import React, { useState, useEffect, useCallback } from 'react';
import { signOut } from 'firebase/auth';
import { auth } from '../config/firebase';
import { useNavigate } from 'react-router-dom';
import { addSubject, getSubjects, deleteSubject, updateSubjectTallies, getOperationCounts } from '../utils/firebaseUtils';
import SubjectList from './SubjectList';
import NewSubjectForm from './NewSubjectForm';
import Timer from './Timer';
import Leaderboard from './Leaderboard';
import AvatarMenu from './AvatarMenu';
import DarkModeToggle from './DarkModeToggle';

const Dashboard = ({ user, darkMode, toggleDarkMode }) => {
  const [subjects, setSubjects] = useState([]);
  const [operationCounts, setOperationCounts] = useState({ readCount: 0, writeCount: 0 });
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const loadSubjects = useCallback(async () => {
    const userSubjects = await getSubjects(user.uid);
    if (userSubjects === null) {
      setError("Rate limit exceeded. Please try again later.");
    } else {
      setSubjects(userSubjects);
      setError(null);
    }
    updateOperationCounts();
  }, [user.uid]);

  useEffect(() => {
    if (user) {
      loadSubjects();
    }
  }, [user, loadSubjects]);

  const updateOperationCounts = () => {
    const counts = getOperationCounts();
    setOperationCounts(counts);
  };

  const handleAddSubject = async (name, tallies) => {
    const result = await addSubject(user.uid, name, tallies);
    if (result === null) {
      setError("Rate limit exceeded. Please try again later.");
    } else {
      loadSubjects();
    }
  };

  const handleDeleteSubject = async (subjectId) => {
    await deleteSubject(subjectId);
    loadSubjects();
  };

  const handleIncrementTally = async (subjectId, currentTallies) => {
    await updateSubjectTallies(subjectId, currentTallies + 1);
    loadSubjects();
  };

  const handleDecrementTally = async (subjectId, currentTallies) => {
    if (currentTallies > 0) {
      await updateSubjectTallies(subjectId, currentTallies - 1);
      loadSubjects();
    }
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/login');
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 dark:bg-gray-900">
      <header className="Header bg-blue-500 dark:bg-blue-700 shadow-lg !p-0">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-3 w-full max-w-full">
          <div className="flex justify-between items-center">
            <h1 className="text-2xl font-bold text-white !m-0 !text-2xl relative group">
              Study Tracker
              <span className="absolute bottom-0 left-0 w-0 h-0.5 bg-yellow-300 transition-all duration-300 ease-in-out group-hover:w-full"></span>
            </h1>
            <div className="flex items-center space-x-4">
              <AvatarMenu onLogout={handleLogout} />
            </div>
          </div>
        </div>
      </header>
      
      <main className="container mx-auto px-4 py-6 max-w-full">
        {error && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
            <strong className="font-bold">Error: </strong>
            <span className="block sm:inline">{error}</span>
          </div>
        )}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          <div className="space-y-6">
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-6">
              <Timer />
            </div>
            
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-6">
              <h2 className="text-xl font-semibold mb-4">Subjects</h2>
              <NewSubjectForm onSubmit={handleAddSubject} />
              <div className="mt-4">
                <SubjectList
                  subjects={subjects}
                  onDelete={handleDeleteSubject}
                  onIncrement={handleIncrementTally}
                  onDecrement={handleDecrementTally}
                />
              </div>
            </div>
          </div>
          
          <div>
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-6">
              <Leaderboard subjects={subjects} />
            </div>
            
          </div>
        </div>
      </main>
    </div>
  );
};

export default Dashboard;