// src/components/Timer.js
import React, { useState, useEffect } from 'react';
import '../App.css';


const Timer = () => {
  const [time, setTime] = useState(20 * 60); // 20 minutes in seconds
  const [isActive, setIsActive] = useState(false);
  const [isAlarmPlaying, setIsAlarmPlaying] = useState(false);

  useEffect(() => {
    let interval = null;
    if (isActive && time > 0) {
      interval = setInterval(() => {
        setTime((time) => time - 1);
      }, 1000);
    } else if (time === 0) {
      clearInterval(interval);
      setIsAlarmPlaying(true);
    }
    return () => clearInterval(interval);
  }, [isActive, time]);

  const toggleTimer = () => {
    setIsActive(!isActive);
    if (isAlarmPlaying) {
      setIsAlarmPlaying(false);
      setTime(20 * 60);
    }
  };

  const resetTimer = () => {
    setIsActive(false);
    setIsAlarmPlaying(false);
    setTime(20 * 60);
  };

  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  return (
    <div className="Timer">
      <div className="TimerDisplay">{formatTime(time)}</div>
      <div className="TimerControls">
        <button onClick={toggleTimer}>
          {isActive ? 'Pause' : (isAlarmPlaying ? 'Stop Alarm' : 'Start')}
        </button>
        <button onClick={resetTimer}>Reset</button>
      </div>
      {isAlarmPlaying && (
        <audio src="/alarm.mp3" autoPlay loop />
      )}
    </div>
  );
};

export default Timer;