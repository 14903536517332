import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyC0kni98gcAsfqNrjoCXAqSFI0ioGau5G4",
  authDomain: "pomodoro-multiple.firebaseapp.com",
  projectId: "pomodoro-multiple",
  storageBucket: "pomodoro-multiple.appspot.com",
  messagingSenderId: "266406013729",
  appId: "1:266406013729:web:18b0f7c015205023996c60"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);