import React, { useState, useRef, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';

const NewSubjectForm = ({ onSubmit }) => {
  const [name, setName] = useState('');
  const [tallies, setTallies] = useState('');
  const [isFormVisible, setIsFormVisible] = useState(false);
  const formRef = useRef(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (name.trim()) {
      onSubmit(name, tallies === '' ? 0 : parseInt(tallies, 10));
      setName('');
      setTallies('');
      setIsFormVisible(false);
    }
  };

  const toggleForm = () => {
    setIsFormVisible(!isFormVisible);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (formRef.current && !formRef.current.contains(event.target)) {
        setIsFormVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="new-subject-wrapper" ref={formRef}>
      {!isFormVisible && (
        <button
          onClick={toggleForm}
          className="add-subject-button bg-blue-500 hover:bg-blue-700 text-white font-bold p-4 rounded focus:outline-none focus:shadow-outline w-16 h-16 flex items-center justify-center"
        >
          Add
        </button>
      )}
      <CSSTransition
        in={isFormVisible}
        timeout={{ enter: 300, exit: 0 }}
        classNames={{
          enter: 'form-appear',
          enterActive: 'form-appear-active',
          exit: 'form-exit',
          exitActive: 'form-exit-active',
        }}
        unmountOnExit
      >
        <form onSubmit={handleSubmit} className="new-subject-form bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 mt-4">
          <h3 className="text-2xl font-bold mb-4 text-gray-800">Add New Subject</h3>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="subjectName">
              Subject Name
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="subjectName"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter subject name"
              required
            />
          </div>
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="initialTallies">
              Initial Tallies
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="initialTallies"
              type="number"
              value={tallies}
              onChange={(e) => setTallies(e.target.value)}
              placeholder="Enter initial tallies"
            />
          </div>
          <div className="flex items-center justify-between">
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full"
              type="submit"
            >
              Submit
            </button>
          </div>
        </form>
      </CSSTransition>
    </div>
  );
};

export default NewSubjectForm;