import React from 'react';

const SubjectList = ({ subjects, onDelete, onIncrement, onDecrement }) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
      {subjects.map((subject) => (
        <div 
          key={subject.id} 
          className="bg-white dark:bg-gray-700 rounded-lg shadow-sm p-4 transition duration-300 ease-in-out transform hover:shadow-md hover:scale-105 hover:bg-blue-100 dark:hover:bg-blue-800"
        >
          <div className="flex justify-between items-center mb-2">
            <h3 className="text-lg font-semibold">{subject.name}</h3>
            <span className="text-sm text-gray-600 dark:text-gray-300">Tallies: {subject.tallies}</span>
          </div>
          <div className="flex justify-between items-center">
            <div>
              <button 
                onClick={() => onIncrement(subject.id, subject.tallies)}
                className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-1 px-2 rounded mr-1 transition duration-300 ease-in-out"
              >
                +
              </button>
              <button 
                onClick={() => onDecrement(subject.id, subject.tallies)}
                className="bg-yellow-500 hover:bg-yellow-600 text-white font-bold py-1 px-2 rounded mr-1 transition duration-300 ease-in-out"
              >
                -
              </button>
            </div>
            <button 
              onClick={() => onDelete(subject.id)}
              className="bg-red-500 hover:bg-red-600 text-white font-bold py-1 px-2 rounded transition duration-300 ease-in-out"
            >
              Delete
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default SubjectList;