// src/utils/firebaseUtils.js
import { collection, addDoc, query, where, getDocs, deleteDoc, updateDoc, doc } from 'firebase/firestore';
import { db } from '../config/firebase';

let readCount = 0;
let writeCount = 0;

const RATE_LIMIT = 100; // operations per minute
const RATE_LIMIT_WINDOW = 60000; // 1 minute in milliseconds
let operationTimestamps = [];

const logOperation = (operation, count) => {
  console.log(`${operation} operation performed. Total ${operation}s: ${count}`);
}

const incrementReadCount = () => {
  readCount++;
  logOperation('Read', readCount);
}

const incrementWriteCount = () => {
  writeCount++;
  logOperation('Write', writeCount);
}

const checkRateLimit = () => {
  const now = Date.now();
  operationTimestamps = operationTimestamps.filter(timestamp => now - timestamp < RATE_LIMIT_WINDOW);
  
  if (operationTimestamps.length >= RATE_LIMIT) {
    console.log("Rate limit exceeded");
    return false;
  }
  
  operationTimestamps.push(now);
  return true;
}

export const addSubject = async (userId, name, tallies) => {
  if (!checkRateLimit()) return null;
  
  try {
    const docRef = await addDoc(collection(db, 'subjects'), {
      userId,
      name,
      tallies: Number(tallies),
      createdAt: new Date()
    });
    incrementWriteCount();
    return docRef.id;
  } catch (error) {
    console.error("Error adding subject: ", error);
    throw error;
  }
};

export const getSubjects = async (userId) => {
  if (!checkRateLimit()) return null;
  
  try {
    const q = query(collection(db, 'subjects'), where('userId', '==', userId));
    const querySnapshot = await getDocs(q);
    incrementReadCount();
    return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  } catch (error) {
    console.error("Error getting subjects: ", error);
    throw error;
  }
};

export const deleteSubject = async (subjectId) => {
  if (!checkRateLimit()) return;
  
  try {
    await deleteDoc(doc(db, 'subjects', subjectId));
    incrementWriteCount();
  } catch (error) {
    console.error("Error deleting subject: ", error);
    throw error;
  }
};

export const updateSubjectTallies = async (subjectId, newTallies) => {
  if (!checkRateLimit()) return;
  
  try {
    await updateDoc(doc(db, 'subjects', subjectId), { tallies: newTallies });
    incrementWriteCount();
  } catch (error) {
    console.error("Error updating subject tallies: ", error);
    throw error;
  }
};

export const getOperationCounts = () => {
  return { readCount, writeCount };
};