import React, { useState } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import '../App.css';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const calculateTotalHours = (subjects) => {
  const totalTallies = subjects.reduce((acc, subject) => acc + subject.tallies, 0);
  return Number(((totalTallies * 20) / 60).toFixed(2));
};

const Leaderboard = ({ subjects }) => {
  const [viewMode, setViewMode] = useState('graph');
  const totalHours = calculateTotalHours(subjects);
  const [whole, decimal] = totalHours.toString().split('.');
  const paddedDecimal = decimal ? decimal.padEnd(2, '0') : '00';
  const sortedSubjects = [...subjects].sort((a, b) => b.tallies - a.tallies);

  const chartData = {
    labels: sortedSubjects.map(s => s.name),
    datasets: [{
      label: 'Tallies',
      data: sortedSubjects.map(s => s.tallies),
      backgroundColor: 'rgba(75, 192, 192, 0.6)',
    }]
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Subject Tallies',
      },
    },
  };

  const toggleViewMode = () => {
    setViewMode(viewMode === 'graph' ? 'text' : 'graph');
  };

  return (
    <div className="Leaderboard">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold">Leaderboard</h2>
        <button
          onClick={toggleViewMode}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          {viewMode === 'graph' ? 'Switch to Text' : 'Switch to Graph'}
        </button>
      </div>
      
      {viewMode === 'graph' ? (
        <Bar data={chartData} options={options} />
      ) : (
        <div className="text-view">
          {sortedSubjects.map((subject, index) => (
            <div key={subject.id} className={`flex justify-between items-center p-2 ${index % 2 === 0 ? 'bg-gray-100 dark:bg-gray-700' : ''}`}>
              <span>{index + 1}. {subject.name}</span>
              <span>{subject.tallies}</span>
            </div>
          ))}
        </div>
      )}

      <h2 className="text-xl font-semibold mt-4">
        Total Hours Studied: {whole}<span className="DecimalSpace">.</span>{paddedDecimal}
      </h2>
    </div>
  );
};

export default Leaderboard;